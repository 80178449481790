<template>
    <div class="breadcrumbs">
        <p
            v-for="(path, index) in paths"
            :key="index"
            class="breadcrumbs__path"
            :class="{ lastpath: index == paths.length - 1 }"
            @click="redirectToPath(path)"
        >
            {{ path.title
            }}<span
                class="breadcrumbs__path__divider"
                v-if="index < paths.length - 1"
            >
                /
            </span>
        </p>
    </div>
</template>

<script>
export default {
    name: "SmartBreadcrumbs",
    props: {
        paths: { type: Array, required: true },
    },
    methods: {
        redirectToPath(path) {
            if (path.href) {
                this.$router.push({ name: path.href });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
    display: flex;
    &__path {
        font-family: "Open Sans";
        margin: 0;
        font-size: 1.6rem;
        font-weight: 500;
        color: #008f4a;
        cursor: pointer;
        user-select: none;
        &__divider {
            margin: 0 10px;
            margin-right: 12px;
            cursor: default;
        }
    }
    .lastpath {
        cursor: default;
        color: black;
    }
}
</style>
