<template>
  <main style="overflow-x: hidden">
    <div v-if="userType === 'retailer'">
      <retailerNavigation />
      <v-main app>
        <div class="page-wrapper retailer-main-container">
          <div class="productList__header">
            <SmartBreadcrumbs
              :class="{ 'mt-5': breadcrumbsPaths.length > 0 }"
              :paths="breadcrumbsPaths"
            />
          </div>
          <router-view></router-view>
        </div>
      </v-main>
    </div>
  </main>
</template>

<script>
// Components
import SmartBreadcrumbs from "@/components/retailer/SmartBreadcrumbs.vue";
// Services
import HomePage from "@/services/HomePage";
import retailerNavigation from "@/components/navigation/retailer.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "ListOfProducts",
  components: {
    retailerNavigation,
    HomePage,
    SmartBreadcrumbs,
  },
  data() {
    return {
      userType: "retailer", // TODO: Get the user type from user object
      showBanner: true,
    };
  },
  mounted() {
    this.generateBreadcrumbs();
  },
  watch: {
    routePath: {
      handler(val) {
        this.generateBreadcrumbs();
      },
    },
  },
  computed: {
    ...mapGetters({
      breadcrumbsPaths: "retailer/Breadcrumbs",
      ProductsCount: "retailer/ProductsCount",
    }),
    routePath() {
      return this.$route.fullPath;
    },
  },
  methods: {
    ...mapActions(["retailer/updateBreadcrumbs"]),
    generateBreadcrumbs() {
      if (this.$route.name === "Retailer Home") {
        this.setBreadcrumbs([]);
      } else if (
        this.$route.name === "Product list" &&
        !this.$route.query.search
      ) {
        this.setBreadcrumbs([
          {
            title: "List of products",
            href: "Retailer Home",
          },
          {
            title: "All categories",
            href: undefined,
          },
        ]);
      } else if (
        this.$route.name === "Product list" &&
        this.$route.query.search
      ) {
        this.setBreadcrumbs([
          {
            title: "List of products",
            href: "Retailer Home",
          },
          {
            title: `Search for "${this.$route.query.search}"`,
            href: undefined,
          },
        ]);
      }
    },
    setBreadcrumbs(paths) {
      this["retailer/updateBreadcrumbs"](paths);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables2.scss";

.no-result {
  color: green;
  padding-top: 250px;
  padding-bottom: 250px;
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.products-list-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, 312px);
  grid-gap: 24px;
  justify-content: start;
  align-items: stretch;
}

.fake_select_wrapper {
  width: 257px !important;
}

.list_banner {
  width: calc(100% - 100px);
  margin-top: 16px;
  margin-left: 84px;

  &.isOpen {
    padding-left: 75px;
  }
}

.closeBanner {
  position: absolute;
  top: 32px;
  left: 100px;
  background: #008f4a;
  color: white;
  border: 1px solid #008f4a;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 6px;
  gap: 8px;
}
.productList__header {
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  width: 100%;
  padding: 0 5px;
  justify-content: space-between;
  align-items: center;
  &__title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
  }
  &__listCount {
    margin: 0;
    color: #667085;
    font-size: 1.3rem;
  }
}
.popular_categories {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 8px;
}

.banner-border {
  border-bottom: 1px solid #e4e7ec;
  margin-top: 24px;
}

.flex-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.flex-container > div {
  text-align: center;
  line-height: 75px;
  font-size: 30px;
  margin-bottom: 15px;
  @media (max-width: 769px) {
    flex: 1 0 30%;
  }
  @media (max-width: 992px) and (min-width: 769px) {
    flex: 1 0 30%;
  }
  @media (max-width: 1200px) and (min-width: 992px) {
    flex: 1 0 30%;
  }
  @media (max-width: 1500px) and (min-width: 1200px) {
    flex: 1 0 10%;
  }
  @media (min-width: 1500px) {
    flex: 1 0 10%;
  }
}
.retailer-main-container {
  @media screen and (min-width: 1621px) {
    width: calc(100% - 315px) !important;
    margin-left: auto;
    margin-right: 40px !important;
  }
  @media screen and (min-width: 300px) {
    width: calc(100% - 110px);
    margin-left: auto;
    margin-right: 20px;
  }
}

.flex-container-small-banners {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.flex-container-small-banners > div {
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
  flex: 6 0 12%;

  @media (max-width: 769px) {
    flex: 6 0 40%;
  }
}
</style>
